import { Button } from '@uv/ui/components/ui/button'
import { DashboardCard, DashboardCardTitle } from '../../ui/card-dashboard'
import { PlusIcon, MinusIcon, CheckIcon, Loader2Icon } from 'lucide-react'
import {
  PauseIcon,
  PlayIcon,
  BoltIcon,
  ArrowUpIcon,
  ArrowDownIcon
} from '@heroicons/react/24/solid'
import { Gauge } from '@/components/ui/gauge'
import { cn } from '@ui/utils'
import { clampFactor } from '@/components/ui/utils'
import { ButtonPressAndHold } from '@/components/ui/button-press-and-hold'
import { Progress } from '@ui/components/ui/progress'
import { TopBar } from '../shared/top-bar'
import { InvertInstallationUiButton } from '../shared/invert-installation-ui-button'
import { Alert } from '../shared/alert'
import { SystemOverrideLayout } from '../shared/settings/service-mode-layout'
import { CuringToggle } from './3-curing/curing-toggle'
import { CardToggable } from './3-curing/card-toggable'
import { parseISO, differenceInMinutes } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { PullerCountdown } from './3-curing/puller-countdown'
import {
  convertDistance,
  convertPressure,
  convertSpeed,
  convertTemperature,
  getDistanceSymbol,
  getPressureSymbol,
  getSpeedSymbol,
  getTemperatureSymbol
} from '@/lib/units'
import { VideoWebRTC } from '../shared/video-webrtc'
import { useMachineVideo } from '@/lib/machine/use-machine-video'
import { clamp, scaleClamp } from '@/lib/utils'
import {
  MAX_AIR_PRESSURE_BAR,
  MAX_AIR_TEMP_C,
  MAX_SPEED_MH,
  MIN_AIR_PRESSURE_BAR,
  MIN_AIR_TEMP_C,
  MIN_SPEED_MH
} from '@/lib/constants'
import { UvStatusPentagon } from './3-curing/uv-status-pentagon'
import { StageSwitcher } from '../shared/stage-switcher'
import { VideoMock } from '../shared/video-mock'
import { LampControls } from '../shared/lamp-controls'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useTranslation } from 'react-i18next'
import { useCloudFinishInstallation } from './3-curing/use-cloud-finish-installation'

export const InstallationStage3Curing = () => {
  const { t } = useTranslation()
  const { reconnect, remoteStream } = useMachineVideo()
  const activeInstallationFileMeta = useDataStoreLocalStorage(s => s.activeInstallationFileMeta)
  const refreshData = useDataStore(s => s.refreshData)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const mockMode = useDataStore(s => s.mockMode)
  const settings = useDataStoreLocalStorage(s => s.settings)

  const cloudFinishInstallation = useCloudFinishInstallation()
  const [isProcessingCloudInstallationFinish, setIsProcessingCloudInstallationFinish] =
    useState(false)

  const isLinerInstallation =
    activeInstallationFileMeta?.installationInfo.installationType === 'LINER'

  const RPM_FACTOR = refreshData?.RPM_FACTORS[0] ?? 0
  const UV_FACTOR = refreshData?.UV_FACTORS[0] ?? 0

  const installationDistance = activeInstallationFileMeta?.installationInfo.distance
  const installationStartedAt = activeInstallationFileMeta?.installationInfo.startedAt

  const pullerTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isPullerLoading, setIsPullerLoading] = useState(false)
  const isPullerDisabled = isPullerLoading || UV_FACTOR === 0
  const pullerDelay = activeInstallationFileMeta?.installationInfo.recipe.delay ?? 0

  const isOtherLiner = activeInstallationFileMeta?.installationInfo.linerId === undefined
  const isOtherChemistry = activeInstallationFileMeta?.installationInfo.chemistryId === undefined
  const initialPullerFactor = isOtherLiner || isOtherChemistry ? 5 : 100

  const startPullerWithDelay = () => {
    if (RPM_FACTOR !== 0) return
    setIsPullerLoading(true)

    pullerTimeoutId.current = setTimeout(() => {
      sendMachineAction({
        in: 'rpm_set',
        factor: initialPullerFactor
      })
      setIsPullerLoading(false)
    }, pullerDelay * 1000)
  }

  const clearPullerTimeout = () => {
    if (!pullerTimeoutId.current) return
    clearTimeout(pullerTimeoutId.current)
    pullerTimeoutId.current = null
  }

  useEffect(() => {
    return () => {
      clearPullerTimeout()
    }
  }, [])

  const speedValue = convertSpeed({
    value: refreshData?.RPM ?? 0,
    from: 'm/h',
    to: settings.unitSpeed
  })
  const speedValueFormatted = isNaN(speedValue)
    ? '-'
    : speedValue === 0
      ? '0'
      : Math.abs(speedValue).toFixed(2)
  // : unitDistance === 'å'
  //   ? speedValue.toExponential()
  //   : speedValue.toFixed(2)

  const pressureValue = convertPressure({
    value: refreshData?.AIR_PRESSURE ?? 0,
    from: 'bar',
    to: settings.unitPressure
  })
  const pressureValueFormatted = isNaN(pressureValue)
    ? '-'
    : pressureValue === 0
      ? '0'
      : pressureValue.toFixed(2)

  const temperatureValue = convertTemperature({
    value: refreshData?.AIR_TEMP ?? 0,
    from: 'c',
    to: settings.unitTemperature
  })

  const temperatureValueFormatted = isNaN(temperatureValue)
    ? '-'
    : temperatureValue === 0
      ? '0'
      : temperatureValue.toFixed(2)

  const handleCompleteInstallation = async () => {
    if (activeInstallationFileMeta?.installationInfo) {
      setIsProcessingCloudInstallationFinish(true)
      await cloudFinishInstallation({
        ...activeInstallationFileMeta?.installationInfo,
        finishedAt: new Date().toISOString()
      })
    }
    sendMachineAction({ in: 'summary_stage' })
    setIsProcessingCloudInstallationFinish(false)
  }

  return (
    <div
      className={cn(
        `grid grid-rows-[30px,1fr] gap-8 h-screen w-screen p-8 pt-6`,
        settings.flipUI ? 'grid-cols-[380px,1fr]' : 'grid-cols-[1fr,380px]'
      )}
    >
      <TopBar />
      <Alert />
      <SystemOverrideLayout />

      <div
        className={cn(
          'relative w-full flex items-start gap-8 select-none',
          settings.flipUI && 'order-3'
        )}
      >
        <div
          className={cn(
            'max-w-[19rem] p-8 bg-zinc-800/40 rounded-2xl font-medium',
            settings.flipUI && 'order-2'
          )}
        >
          <UvStatusPentagon />
          <div className="relative w-60 min-h-30 flex flex-col items-center">
            <Gauge
              percentage={scaleClamp(Math.abs(speedValue), [MIN_SPEED_MH, MAX_SPEED_MH], [0, 100])}
              arcRange={120}
              strokeWidth={4}
              className="absolute top-0 left-0 w-60 text-accent"
            />
            <div className="mt-10 flex gap-2 items-baseline">
              <span className="text-4xl font-bold text-accent">{speedValueFormatted}</span>
              <span className="text-lg font-bold text-accent uppercase">
                {getSpeedSymbol(settings.unitSpeed)}
              </span>
            </div>
            <span className="text-md text-muted-foreground">{t('installation.curing.speed')}</span>
          </div>

          <div className="relative w-60 min-h-30 flex flex-col items-center mt-10">
            <Gauge
              percentage={scaleClamp(
                pressureValue,
                [MIN_AIR_PRESSURE_BAR, MAX_AIR_PRESSURE_BAR],
                [0, 100]
              )}
              arcRange={120}
              strokeWidth={4}
              className="absolute top-0 left-0 w-60 text-accent"
            />
            <div className="mt-10 flex gap-2 items-baseline">
              <span className="text-4xl font-bold text-accent">{pressureValueFormatted}</span>
              <span className="text-lg font-bold text-accent uppercase">
                {getPressureSymbol(settings.unitPressure)}
              </span>
            </div>
            <span className="text-md text-muted-foreground">
              {t('installation.curing.pressure')}
            </span>
          </div>

          <div className="relative w-60 min-h-30 flex flex-col items-center mt-10">
            <Gauge
              percentage={scaleClamp(temperatureValue, [MIN_AIR_TEMP_C, MAX_AIR_TEMP_C], [0, 100])}
              arcRange={120}
              strokeWidth={4}
              className="absolute top-0 left-0 w-60 text-accent"
            />
            <div className="mt-10 flex gap-2 items-baseline">
              <span className="text-4xl font-bold text-accent">{temperatureValueFormatted}</span>
              <span className="text-lg font-bold text-accent uppercase">
                {getTemperatureSymbol(settings.unitTemperature)}
              </span>
            </div>
            <span className="text-md text-muted-foreground">
              {t('installation.curing.air-temperature')}
            </span>
          </div>
        </div>

        <div className="flex flex-col flex-grow h-full gap-8">
          <div className="flex gap-8">
            <CardToggable className="bg-zinc-700/40">
              {({ toggled: isDistanceRemainingMode }) => {
                if (!installationDistance || !refreshData) return <>-</>
                const distanceLeft = Math.max(0, installationDistance - refreshData.DISTANCE)
                const percentageCompleted = clamp(
                  100 - (distanceLeft / installationDistance) * 100,
                  0,
                  100
                )

                const valueInPreferredUnit = convertDistance({
                  value: isDistanceRemainingMode ? distanceLeft : refreshData.DISTANCE,
                  from: 'm',
                  to: settings.unitDistance
                })
                const valueFormatted = isNaN(valueInPreferredUnit)
                  ? '-'
                  : valueInPreferredUnit === 0
                    ? '0'
                    : valueInPreferredUnit.toFixed(2)

                return (
                  <>
                    <div className="flex flex-col gap-4">
                      <DashboardCardTitle className="mb-0">
                        {isDistanceRemainingMode
                          ? t('installation.curing.distance-left')
                          : t('installation.curing.distance')}
                      </DashboardCardTitle>

                      <div className="flex gap-2 items-baseline">
                        <span className="text-accent text-5xl font-bold">{valueFormatted}</span>
                        <span className="text-accent text-2xl font-bold leading-none">
                          {getDistanceSymbol(settings.unitDistance)}
                        </span>
                      </div>
                    </div>
                    <Progress value={percentageCompleted} className="mt-6 bg-black" />
                  </>
                )
              }}
            </CardToggable>

            <CardToggable className="bg-zinc-700/40">
              {({ toggled: isTimeRemainingMode }) => {
                if (!installationStartedAt) return <>-</>
                if (!installationDistance || !refreshData) return <>-</>

                const startedDate = parseISO(installationStartedAt)
                const now = new Date()
                const minutesElapsed = differenceInMinutes(now, startedDate)

                const distanceLeft = installationDistance - refreshData.DISTANCE
                const speedInMetersPerHour = refreshData.RPM
                const minutesLeft = distanceLeft / (speedInMetersPerHour / 60)

                // const totalMinutesRequired = minutesElapsed + minutesLeft
                // const percentageCompleted = clamp(
                //   (minutesElapsed / totalMinutesRequired) * 100,
                //   0,
                //   100
                // )

                const isInfinity = !isFinite(minutesLeft)

                return (
                  <>
                    <div className="flex flex-col gap-4">
                      <DashboardCardTitle className="mb-0">
                        {isTimeRemainingMode
                          ? t('installation.curing.time-left')
                          : t('installation.curing.time-elapsed')}
                      </DashboardCardTitle>

                      <div className="flex gap-2 items-baseline">
                        <span className="text-accent text-5xl font-bold">
                          {isTimeRemainingMode
                            ? isInfinity || minutesLeft < 0
                              ? '?'
                              : minutesLeft.toFixed(0)
                            : minutesElapsed.toFixed(0)}
                        </span>
                        <span className="text-accent text-2xl font-bold leading-none">min</span>
                      </div>
                    </div>
                    {/* <Progress value={percentageCompleted} className="mt-6 bg-black" /> */}
                  </>
                )
              }}
            </CardToggable>
          </div>

          <div className="flex">
            {mockMode ? (
              <VideoMock playbackRate={Math.abs(RPM_FACTOR / 100)} />
            ) : (
              <VideoWebRTC
                reconnect={reconnect}
                remoteStream={remoteStream}
                className="flex-grow rounded-2xl w-auto max-h-full"
              />
            )}
          </div>
        </div>

        <InvertInstallationUiButton />
      </div>

      <div className="flex flex-col min-w-[380px] max-w-[380px] mx-auto gap-6">
        <StageSwitcher />
        <div className="flex gap-4">
          <CuringToggle
            label={t('installation.curing.uv-led-button')}
            aria-label="Toggle UV LED"
            pressed={Boolean(UV_FACTOR)}
            onPressedChange={() => {
              clearPullerTimeout()

              sendMachineAction({
                in: 'uv_set',
                factor: UV_FACTOR ? 0 : 100
              })

              // Puller only should be engaged for Liner installation
              if (!isLinerInstallation) return

              // Puller should be started with delay
              if (UV_FACTOR === 0) {
                startPullerWithDelay()
              }

              // Puller should be stopped when UV is off
              if (UV_FACTOR !== 0) {
                sendMachineAction({
                  in: 'rpm_set',
                  factor: 0
                })
                setIsPullerLoading(false)
              }
            }}
          >
            <BoltIcon className="w-8" />
          </CuringToggle>

          <CuringToggle
            label={t('installation.curing.puller-button')}
            aria-label="Puller Stop"
            pressed={RPM_FACTOR !== 0}
            onPressedChange={() => {
              sendMachineAction({
                in: 'rpm_set',
                factor: RPM_FACTOR === 0 ? 100 : 0
              })
            }}
            disabled={isPullerDisabled}
          >
            {isPullerLoading ? (
              <PullerCountdown duration={pullerDelay} />
            ) : RPM_FACTOR === 0 ? (
              <PlayIcon className="w-8" />
            ) : (
              <PauseIcon className="w-8" />
            )}
          </CuringToggle>

          <CuringToggle
            label={
              RPM_FACTOR < 0
                ? t('installation.curing.pushing-button')
                : t('installation.curing.pulling-button')
            }
            aria-label="Reverse Direction"
            pressed={RPM_FACTOR < 0}
            className="data-[state=on]:bg-yellow-700"
            onPressedChange={() => {
              sendMachineAction({
                in: 'rpm_set',
                factor: RPM_FACTOR === 0 ? 0 : RPM_FACTOR * -1
              })
            }}
            disabled={!isLinerInstallation || isPullerDisabled}
          >
            {RPM_FACTOR < 0 ? <ArrowUpIcon className="w-8" /> : <ArrowDownIcon className="w-8" />}
          </CuringToggle>
        </div>

        <DashboardCard className="w-full flex gap-6 justify-between bg-zinc-900/70 select-none">
          <ButtonPressAndHold
            className="w-16 h-16 p-6 bg-zinc-700 hover:bg-zinc-700 text-foreground disabled:opacity-1 disabled:bg-zinc-700/40 disabled:text-foreground/30 rounded-2xl"
            holdActivationDelay={0}
            holdActionRepeatInterval={200}
            onAction={() => {
              sendMachineAction({
                in: 'rpm_set',
                factor:
                  RPM_FACTOR < 0
                    ? clampFactor(RPM_FACTOR + 10, { min: -100, max: 0 })
                    : clampFactor(RPM_FACTOR - 10, { min: 0, max: 100 })
              })
            }}
            disabled={isPullerDisabled}
          >
            <MinusIcon className="w-4" />
          </ButtonPressAndHold>

          <div className="flex flex-col text-center">
            <span className={cn('text-xl font-bold', isPullerDisabled && 'text-foreground/40')}>
              {t('installation.curing.puller-speed')}
            </span>
            <span
              className={cn('text-accent text-2xl font-bold', isPullerDisabled && 'opacity-40')}
            >
              {Math.abs(RPM_FACTOR)}%
            </span>
          </div>

          <ButtonPressAndHold
            className="w-16 h-16 p-6 bg-zinc-700 hover:bg-zinc-700 text-foreground disabled:opacity-1 disabled:bg-zinc-700/40 disabled:text-foreground/30 rounded-2xl"
            holdActivationDelay={0}
            holdActionRepeatInterval={200}
            onAction={() => {
              sendMachineAction({
                in: 'rpm_set',
                factor:
                  RPM_FACTOR < 0
                    ? clampFactor(RPM_FACTOR - 10, { min: -100, max: 0 })
                    : clampFactor(RPM_FACTOR + 10, { min: 0, max: 100 })
              })
              // sendInstallationAction({
              //   in: 'rpm_configure',
              //   list: [2512, [[0, 0]]]
              // })
            }}
            disabled={isPullerDisabled}
          >
            <PlusIcon className="w-4" />
          </ButtonPressAndHold>
        </DashboardCard>

        <LampControls />

        <Button
          className="w-full h-auto text-xl py-10 flex justify-center items-center gap-2 text-foreground bg-zinc-700 rounded-3xl hover:bg-zinc-700 select-none"
          onClick={handleCompleteInstallation}
        >
          {isProcessingCloudInstallationFinish ? (
            <Loader2Icon className="mr-1 h-6 w-6 animate-spin" />
          ) : (
            <CheckIcon strokeWidth={3} className="w-8" />
          )}

          <span>{t('installation.curing.complete-installation-button')}</span>
        </Button>
      </div>
    </div>
  )
}
