import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { MachineAlarm, machineAlarms } from '@uv/machine'

export const SystemOverrideLayout = () => {
  const alarmOverrides = useDataStore(s => s.machineConfiguration?.overrides)
  const activeInstallationFileMeta = useDataStoreLocalStorage(s => s.activeInstallationFileMeta)

  const isLinerInstallation =
    activeInstallationFileMeta?.installationInfo.installationType === 'LINER'

  // T-LIner and Patch installations require overriding `ALARM_PRESSURE` and `ALARM_SLIP`
  const specialOverrides = isLinerInstallation
    ? []
    : (['SLIP', 'PRESSURE'] satisfies MachineAlarm[])

  const overrides = alarmOverrides
    .map((override, i) => (override ? machineAlarms[i] : undefined))
    .filter((item): item is MachineAlarm => {
      console.log('item', item)
      return (
        item !== undefined && !(specialOverrides as MachineAlarm[]).includes(item as MachineAlarm)
      )
    })

  console.log('overrides', alarmOverrides, overrides, specialOverrides)

  if (overrides.length === 0) return null

  return (
    <div className="fixed top-0 left-0 h-full w-full border-8 border-red-500 pointer-events-none z-10">
      <div className="mt-2 ml-2">
        <span className="text-2xl text-red-500 font-bold">SERVICE MODE: </span>
        <span className="text-2xl text-red-500 font-bold">{overrides.join(', ')}</span>
      </div>
    </div>
  )
}
